.survey-counter {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;

    .survey-counter-text {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: var(--text-lightGrey);

        span {
            color: var(--text-mediumGrey);
        }
    }

    .add-more {
        font-size: 15px;
        text-align: center;
        color: var(--transform-darkBlueTwo);
        line-height: 18px;
        cursor: pointer;
    }
}