@import '../../../assets/variables.scss';

.text-editor-container {

    .preview {
        padding: 1rem;
        margin-top: 1rem;
        height: 200px;
    }

    .wrapper-class {
        border: 1px solid #EEF0F4;
    }

    .editor-class {
        padding: 0 10px;
        height: 250px;

        .DraftEditor-root {
            height: 220px;
        }
    }

    .toolbar-class {
        background-color: #EEF0F4;
    }

    .rdw-editor-toolbar {
        padding: 12px 16px 12px 7px;

        &>div {
            margin-bottom: 0px;
        }

        .button-wrapper:last-child {
            margin-left: 12px;
        }
    }

    .rdw-option-wrapper {
        background: transparent;

        &:hover {
            box-shadow: none;
            border-bottom: 1px solid var(--transform-darkBlueTwo);
        }
    }

    .rdw-embedded-wrapper {
        margin-right: auto;
    }

    .button-wrapper {
        margin: 0px;

        button {
            background-color: white;
            color: var(--transform-darkBlueTwo);
            transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
            box-shadow: none;
            padding: 3px 10px;
            max-height: 30px;
            box-shadow: 0px 0px 0px 1.5px inset var(--transform-darkBlueTwo);

            &:hover {
                background-color: #DCE0E3;
                box-shadow: 0px 0px 0px 1.5px inset var(--transform-darkBlue);
                color: var(--transform-darkBlue);
            }
        }
    }

    .toolbar {
        border-radius: 4px 4px 0 0;
    }
}