@import "./assets/variables.scss";

.main-container-landing-page {
  background: #3949b3 url("./assets/images/intro-image-people.png") no-repeat
    center;
  background-size: cover;
  height: 100%;
  justify-items: center;
  display: flex;
  flex-direction: column;
  .transform-logo-container {
    width: fit-content;
    padding: 0px 0;
    border-bottom: 4px solid var(--colors-blue);
    justify-content: center;
    .transform-logo-img {
      margin-left: 20%;
    }
  }
  .domain-input-container {
    margin-top: 40%;
    height: auto;
    width: 500px;
    background-color: white;
    
    .content-container{
      padding-bottom: 15px;
    }
    .domain-error {
      width: 80%;
      justify-content: center;
      margin-left: 35px;
      margin-top: -25px
    }
  }
  h5 {
    margin: 15px;
    justify-content: center;
  }
  .input-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 20px;
    margin: 20px;
  }
  .domain-input {
    width: 50%;
  }
 
  .submit-button {
    font-weight: 700;
    margin-left: auto;
  }
}
