.default-style-edit-box {
    display: flex;
    flex-direction: column;


    .radio {
        .color-half {
            width: 36px;
            height: 36px;
            border-radius: 18px;
        }
    }

    .upload-button:hover {
        color: var(--transform-darkBlue) !important;
        border: 1px solid var(--transform-darkBlue) !important;
        background-color: var(--app-lightestGrey) !important;

        svg path{
            fill: var(--transform-darkBlue) !important;
        }
    }

    .color-picker-button {
        transition: background-color 0.3s ease-in-out;
    }

    .color-picker-button:hover {
        background-color: var(--app-lightestGrey) !important;
    }
}