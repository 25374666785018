@import '../../../assets/variables';

.survey-dashboard-container {
  width: 100%;
  justify-content: center;

  .user-actions {
    margin-top: 72px;
    justify-content: center;
    width: 80%;
    padding-bottom: 30px;
    border-bottom: 2px solid var(--colors-lightGray);

    .user-action {
      padding: 15px;
      text-align: center;
      cursor: pointer;

      .logo {
        width: 68px;
        height: auto;
      }

      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        margin: 10px 0;
      }

      .details {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        color: var(--colors-gray);
      }
    }

    .user-action:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: flex;
  padding: 20px;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(96, 96, 156, 0.36);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.template-header {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 10px;

}