@import '../../../../assets/variables';

.question-container {
  min-height: 100px;
  width: calc(100% - 24px);
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
  border-left: solid 24px var(--colors-mediumGray);
  filter: drop-shadow(0px 5px 10px #ededed);
  display: flex;
  flex-direction: column;
  float: right;

  .question {
    flex-grow: 1;
    padding: 16px 20px 0;
    position: relative;
    min-height: 50px;

    .title {
      color: var(--colors-darkGray);

      .question-title {
        font-size: 20px;
        font-weight: 700;


        

        .MuiFormLabel-root, input {
          font-weight: 600;
          font-size: 20px;
        }
      }

      .question-explanation-container {

        .question-explanation {
          width: 90%;
        }

        .remove-button {
          color: var(--colors-blue);
          background-color: $color-light-blue;
          margin-left: 10px;

          .remove-icon {
            color: white;
            margin-right: 5px;
          }
        }
      }

      .MuiFormLabel-root {
        color: var(--colors-mediumGray);
        font-style: italic;
      }

      .MuiInputLabel-shrink {
        display: none;
      }

      .MuiInput-underline:before {
        border-bottom:  1px solid #D9D9D9;
      }

      input {
        padding-bottom: 10px;
      }
    }

    .expand.icon {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .move-up-icon {
      position: absolute;
      right: 80px;
      top: 20px;
    }

    .move-down-icon {
      position: absolute;
      right: 50px;
      top: 20px;
    }

    .movee

    .question-collapsed {
      .collapse-with-number {
        .number {
          position: absolute;
          top: 9px;
          left: 9px;
          color: var(--colors-blue);
          font-weight: bold;
        }
      }
    }
  }

  .settings {
    height: 58px;
    justify-content: space-between;
    border-top: 1px solid var(--colors-lightGray);
    padding: 0 16px;

    .add-section-elements {
      display: flex;

      .settings-item {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .settings-item:not(:last-child) {
      margin-right: 10px;
    }

    .settings-right {

      .settings-item.icon {
        cursor: pointer;

        path {
          fill: var(--colors-blue);
        }
      }
    }
  }

  .drag-handle {
    cursor: grab;
    height: 16px;
    width: 10px;
    background-color: transparent;
    position: absolute;
    left: -17px;
    top: calc(50% - 8px); // Adjust positioning if necessary
  }
}

.question-container.section {
  width: calc(100% - 20px);
}

.question-container.focus {
  border-color:#1B4972
}
