.sidebar {
    align-self: stretch;
    background-color: white;
    min-height: calc(100vh - 56px);
    position: fixed;
    top: 56px;

    .sidebar-tab {
        display: flex;
        justify-content: center;
        padding: 18px 17px;
        cursor: pointer;

        &.active {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background-color: var(--transform-yellow);
            }

            svg path {
                fill: var(--transform-black);
            }
        }

        svg path {
            transition: fill 0.3s ease-in-out;
        }

        &:hover {
            svg path {
                fill: var(--transform-black);
            }
        }
    }
}