
.section {
  .section-container-flex-column-center {
    // section will be centered on screen
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 24px;

  }
  .fab-button {
    padding: 10px;
    float: right;
  }
}
