.app-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px #9B9B9B40, 0px 4px 8px 0px #9B9B9B26;
    border-radius: 4px;
    padding: 36px 16px 34px 16px;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #FFFFFF;

    @media screen and (max-width: 600px){
        width: calc(50% - 16px)
    }

    .app-card-icon{
        display: flex;
        justify-content: center;
    }

    .app-card-description{
        h3{
            margin: 10px 0px 0px 0px;
            text-align: center;
            font-family: 'Crimson Text', serif;
            font-size: 22px;
            line-height: 28px;
            font-weight: 700;
            letter-spacing: -1px;
            color: var(--transform-darkBlue);
        }

        p{
            margin: 10px 0px 0px 0px;
            font-size: 12px;
            line-height: 16px;
            max-width: 176px;
            text-align: center;
            color: var(--text-lightGrey);
        }
    }

    &:hover{
        box-shadow: 0px 4px 8px 0px #1B49726B, 0px 4px 4px 0px #1B49720A;
    }
}