.signin-page {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: white;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}