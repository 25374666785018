.dashboard-container {
    display: flex;
    flex-direction: column;
    margin: 150px auto 0px auto;
    max-width: 1320px;
    width: 100%;

    .dashboard-header {
    display: flex;
    align-items: center;
    gap: 10px;

    h2 {
        font-size: 22px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0;
    }
    }

    .dashboard-cards {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    }
}

@media screen and (max-width: 1380px){
    .dashboard-container {
        width: 90%;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 600px){
    .dashboard-container {
        width: 95%;
       align-items: center;
    
    }
    .dashboard-container .dashboard-cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
    }

    .dashboard-cards .css-1cz1unu {
        width: 100%;
    }
}