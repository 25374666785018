 .create-new-survey-dialog {
     box-shadow: 0px 2px 10px 0px #0000004D;

     .create-new-survey-dialog-types {
         display: flex;
         gap: 24px;
         padding: 54px 40px;
         justify-content: center;

         .create-new-survey-dialog-type {
             width: initial;
             height: initial;
             background-color: #FFFFFF;
             display: flex;
             flex-direction: column;
             align-items: center;
             justify-content: center;
             padding: 40px 16px;
             max-width: 210px !important;
             height: 230px !important;
             box-sizing: border-box;
             border-radius: 4px;
             box-shadow: 0px 0px 0px 1px #D7D7D7;
             cursor: pointer;
             transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;

             h3 {
                 font-size: 14px;
                 font-weight: 600;
                 margin-top: 24px;
                 letter-spacing: 0.15px;
                 text-align: center;
                 color: var(--transform-black);
             }

             p {
                 font-size: 12px;
                 line-height: 16px;
                 margin-top: 6px;
                 text-align: center;
                 color: #666666;
                 text-transform: initial;
             }

             &:hover {
                 box-shadow: 0px 0px 0px 1px var(--transform-darkBlue);
                 background-color: var(--app-lightestGrey);
             }
         }
     }
 }