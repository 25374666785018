@import '../../../../assets/variables';

.questions-tab-container {
  .welcome-data {
    width: 100%;
    height: 285px;
    background-color: #1B4972;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 20px;

    .survey-name {
      width: 65%;
    }

    .survey-name input {
      font-size: 40px !important;
      font-weight: 600 !important;
      line-height: 24px;
      letter-spacing: 0.073px;
      color: white;
      margin-bottom: 12px;
      padding: 0px;
    }

    .survey-name .MuiInput-underline {
      &:before {
        border-bottom: 2px solid white;
      }
      &:after {
        border-bottom: 2px solid white;
      }
    }

    label {
      color: white;
    }

    .MuiInputBase-root {
      color: white;
      font-size: 46px;
    }
  }

  .create-question {
    background-color: white;
    border-radius: 5px;
    height: 285px;
    justify-content: center;
    transition: box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 8px 0px #1B49726B, 0px 4px 4px 0px #1B49720A;
    }

    .text {
      font-size: 28px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.073px;
      text-align: center;
      color: #282828;
      margin-top: 20px;
    }
  }

  .droppable {
    width: 100%;

    .draggable {
      overflow: hidden;
    }
  }

  .questions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 100px;

    .add.icon {
      align-self: center;
    }
  }

  .icon {
    cursor: pointer;
  }
  // .icon:hover circle {
  //   fill: #0C255C !important;
  // }
}

.add-icon:hover circle {
  fill: #0C255C !important;
  cursor: pointer;
}
