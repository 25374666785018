.phone-input {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;

    &:hover {
        border: 1px solid var(--text-mediumGrey);

        .react-international-phone-input-container .react-international-phone-country-selector-button,
        .react-international-phone-input-container .react-international-phone-input {
            border: 1px solid var(--text-mediumGrey);
        }
    }

    &:focus-within {
        border: 1px solid var(--transform-darkBlueTwo) !important;

        .react-international-phone-input-container .react-international-phone-country-selector-button,
        .react-international-phone-input-container .react-international-phone-input {
            border: 1px solid var(--transform-darkBlueTwo);
        }

        .MuiFormLabel-root {
            color: var(--transform-darkBlueTwo);
        }
    }

    .react-international-phone-country-selector-button__dropdown-arrow {
        margin-right: 0px;
    }

    .react-international-phone-input-container .react-international-phone-country-selector-button {
        height: 100%;
        margin-right: 0px;
        border-right: none !important;
        border: none !important;
        padding: 0px 6px 0px 12px;

        .react-international-phone-flag-emoji {
            width: initial;
            margin: 0;
        }

        &:hover {
            border-radius: none;
        }
    }

    .react-international-phone-country-selector-button__button-content{
        gap: 6px;
    }

    .react-international-phone-input-container .react-international-phone-input {
        border-radius: 0px;
        height: 100%;
        border: none !important;
        border-left: none !important;
        border-right: 1px solid rgba(0, 0, 0, 0.23) !important;
        padding: 0px;
        color: #828282;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        position: relative;

        &:hover {
            border: 1px solid var(--text-mediumGrey);
        }
    }

    div:has(.MuiInputLabel-root) {
        width: 100%;
    }

    .react-international-phone-input-container .react-international-phone-country-selector-button:hover {
        border: 1px solid var(--text-mediumGrey);
        background-color: white;
    }

    .MuiInputBase-root {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: none;
    }

    .MuiInputBase-input {
        border-left: none;
        border: none !important;
    }

    .MuiOutlinedInput-root {
        fieldset {
            border-left: none !important;
            border: none !important;
        }
    }

    .MuiFormLabel-root {
        background-color: white;
    }

    .react-international-phone-country-selector-dropdown {
        z-index: 100;
        outline: none;
        border: none;
        border-radius: 4px;
        box-shadow: 0px 1px 2.5px 0px #7B7D8982;
        margin-top: 10px;
    }
}