.sort {
    display: flex;
    gap: 12px;

    .sort-icon {
        padding: 0px;
        border-radius: 4px;
    }

    .sort-button {
        padding: 0px;
        border-radius: 4px;
        font-size: 14px;
        color: var(--text-lightGrey);
        transition: all 0.3s ease-in-out;
        font-family: 'Roboto', sans-serif;

        svg {
            margin-left: 8px;

            path {
                transition: all 0.3s ease-in-out;
            }
        }


        &:hover {
            background-color: transparent;
            color: var(--transform-darkBlueTwo);

            svg path {
                fill: var(--transform-darkBlueTwo);
            }
        }
    }
}