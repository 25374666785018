.filter {
    margin: 0 auto;
    display: flex;
    gap: 6px;

    @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
        justify-content: center;
        
    }
}