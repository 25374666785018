.multi-select-question-container {
  .style-section {
    display: inline-flex;
    align-items: center;
    gap: 16px;

    .simple-select {
      width: 172px;
      height: 40px;
    }
  }

  .MuiListItem-gutters {
    padding-left: 0 !important;
    padding-top: 10px !important;
  }

  .MuiButtonBase-root {
    margin-right: 15px;
  }
  // .MuiButtonBase-root:hover {
  //   background-color: transparent !important;
  //   // cursor: auto;
  // }

  .MuiListItem-root {
    padding-bottom: 0px;
    padding-top: 24px;
  } 

  .MuiListItem-root:first-child {
    padding-top: 4px;
  }

  .add-option {
    margin-bottom: 32px !important;
  }


  .option-list {

    .text-field {
      padding-top: 5px;
    }
  }

  .add-option-btn {
    margin-left: 65px;
    margin-top: 20px;
  }

  .upload-image {
    justify-content: left;
  }

  .max-options-number {
    width: 45px;
    height: 15px;
  }

  .image-list-item {
    display: inline-flex;
    .image-text-field {
      position: absolute;
      margin: 70px 0 0 50%;
      width: 190px;
      height: 140px;
    }
  }

  .number-of-respondent-options {
    display: grid;
    align-items: center;
    grid-column: 1/2;
    grid-row-gap: 15px;
    margin-left: 60px;
    padding-top: 40px;

    .responses-label {
      padding-bottom: 10px;
      margin-left: -60px;
    }

    .number-of-input {
      width: 45px;
      height: 15px;
    }
  }

  .settings-button {
    text-transform: none;
    font-size: 18px;
    color: #282828;

    label {
      margin-left: 6px;
    }
  }

  .text-button-option .MuiInputBase-root {
    border-radius: 30px !important;
  }

  .text-button-box {
    display: grid;
    grid-template-columns: repeat(4, 275px); /* Keeps 4 columns, each 213px wide */
    justify-content: start; /* Align items to the start of the grid area */
  }

  .text-button-option {
    display: flex;
    align-items: center;
    width: 100%; /* Takes full width of the grid cell */
    text-align: center;
    box-sizing: border-box; /* Ensures padding and border are included in the element's width */
    padding: 0; /* Adjust padding if necessary */
    border: 0; /* Adjust border if necessary */
  }

  .text-button-option .MuiInputBase-root {
    border-radius: 30px !important;
    /* Ensure no additional padding or border here that could affect width */
    padding: 0 !important;
    border: 0 !important;
  }

  .text-button-option .MuiInputBase-input {
    text-align: center;
    font-size: 15px;
    /* No additional width-affecting styles here */
  }

  .image-box {
    display: grid;
    grid-template-columns: repeat(4, 233px); /* Keeps 4 columns, each 213px wide */
    justify-content: start; /* Align items to the start of the grid area */
  }


  #image-delete {
    cursor: pointer;
    padding: 6px;
    margin-bottom: 3px;
    margin-right: -6px;
  }

  #image-delete:hover svg circle {
    fill: #eef0f4;
  }
  .image-text-input-field .MuiInputBase-root{
    text-align: center !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;

  }

  .upload-placeholder {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top: 1px solid #D7D7D7 !important;
    border-left: 1px solid #D7D7D7 !important;
    border-right: 1px solid #D7D7D7 !important;
    border-bottom: none !important;
  }

  .upload-placeholder:hover {
    background-color: #eef0f4 !important;
  }

  #outlined-helperText {
    height: 31px !important;
    width: 213px !important;
    text-align: left;
}

.image-box #outlined-helperText {
  text-align: center !important;
}

.image-text-input-field  #outlined-helperText:hover {
  background-color: #eef0f4 !important;
}

.text-button-box #outlined-helperText {
    height: 31px !important;
    width: 213px !important;
    text-align: center;
  }



}

//Advanced settings
.advanced-settings-multi-line {
  display: grid;
  align-items: center;
  grid-column: 1/2;
  grid-row-gap: 15px;
  margin-left: 20px;

  .customization-label {
    margin: 20px 0 5px 0;
  }

  .button-and-response-customization {
    display: inline-flex;
    gap: 15%;

    .text-in-button {
      align-items: center;
      display: grid;
      grid-column: 1/2;
      grid-row-gap: 10px;
      margin-left: 40px;
    }

    .text-in-response {
      align-items: center;
      display: grid;
      grid-column: 1/2;
      grid-row-gap: 10px;
      margin-right: 30px;
    }
  }

  .source-of-options {
    display: inline-flex;
    align-items: center;
    gap: 15px;
    margin-top: 25px;

    .simple-select {
      width: 172px;
      height: 40px;
    }
  }
}


.upload-image {
  justify-content: left;
  // margin-top: 19px;
}



.max-options-number {
  width: 45px;
  height: 15px;
}

.image-list-item {
  display: inline-flex;
  .image-text-field {
    position: absolute;
    margin: 70px 0 0 50%;
    width: 190px;
    height: 140px;
  }}

  .check-button-box .MuiListItem-gutters {
    padding-top: 24px !important;
  }

  .icon-button-start { 
    padding-right: 0px !important;
  }

