@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.app-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
}

.main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #EDEDED;
    margin-top: 56px;
}

/*some common styles*/
.flex-center {
    display: flex;
    align-items: center;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button-blue {
    color: white !important;
    background-color: #3949B3 !important;
    fill: white !important;
    padding: 0 12px !important;
}

.button-blue path {
    fill: white !important;
}

.css-1cf57c4-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked:hover {
    background-color: 'none !important',
}

.css-1guyrzn-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked:hover {
    background-color: 'none !important',
}

.MuiCheckbox-root:hover {
    color: #0C255C
}