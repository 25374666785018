.sent-email-info{
    &>div:nth-child(2),
    &>div:nth-child(3){
        position: relative;
        &::before{
            content: '|';
            position: absolute;
            left: -18px;
            width: 1px;
            color: var(--text-lightGrey);
        }
    }
}