@import '../../../../../../assets/variables';

.single-line-text-question-container {
  .text-field {
    width: 45px;
    height: 15px;
  }

  .answer-details {
    label {
      display: inline-block;
      vertical-align: center;
      margin-left: 5px;
      padding: 10px 15px 8px 0;
    }
  }

  .answer-validation {
    display: inline-block;
    horiz-align: left;
    padding-left: 3px;
  }

  .answers-per-question {
    margin-left: 35px;

    label {
      display: inline-block;
      vertical-align: center;
      margin-left: 5px;
      padding: 10px 15px 8px 0;
      color:#4F4F4F ;
    }

    .minimum-number-of-responses {
      padding-bottom: 15px;

      label {
        color:#4F4F4F ;
      }
    }
  }
  .settings-button {
    text-transform: none;
    font-size: 18px;
    color: #282828;
  }
}

//Advanced settings
.advanced-settings-single-line {
  display: grid;
  align-items: center;
  grid-column: 1/2;
  grid-row-gap: 15px;
  margin-left: 70px;
  .customization-label{
    margin: 20px 0 5px 30px;
    
  }
  .button-and-response-customization {
    display: inline-flex;
    gap: 15%;

    .text-in-button {
      align-items: center;
      display: grid;
      grid-column: 1/2;
      grid-row-gap: 10px;
      margin-left: 40px;
    }

    .text-in-response {
      align-items: center;
      display: grid;
      grid-column: 1/2;
      grid-row-gap: 10px;
      margin-right: 30px;
    }
  }
}

