@import '../assets/variables';

.header-container {
  height: fit-content;
  background-color: var(--transform-black);
  padding: 9px 20px;
  gap: 16px;
  // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  position: fixed;
  width: 100%;
  box-sizing: border-box;

  .transform-logo {
    // height: 55px;
    width: auto;
    cursor: pointer;
  }

  .app {
    cursor: pointer;

    .app-name{
      display: flex;
      align-items: center;
      gap: 5px;
      text-decoration: none;
    }

    svg {
      height: 17px;
      width: fit-content;
    }

    h2 {
      margin: 0;
      color: white;
      font-family: 'Crimson Text', sans-serif;
      font-weight: 700;
      font-size: 20px;
    }

    &:has(.logo) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -8px;
        width: 1px;
        height: 36px;
        background-color: #6D6D6D;

      }
    }
  }

  .survey-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 20px;

    // Need design for this device < 900px
    @media screen and (max-width: 900px) {
      display: none;
    }

    h6 {
      span:first-child {
        color: var(--colors-mediumGray);
        cursor: pointer;
      }

      span:last-child {
        color: white;
      }
    }

    .survey-status-label {
      padding: 2px 12px;
      font-size: 14px;
      font-weight: 700;
      font-family: 'Roboto';
      line-height: 24px;
      box-shadow: none;
      gap: 8px;

      .icon{
        max-width: 16px;
        max-height: 16px;
      }
    }
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
    margin-right: 2px;

    svg {
      cursor: pointer;
    }

    svg path {
      transition: fill 0.25s ease-in-out;
    }

    svg:not(.circle):hover path {
      fill: var(--transform-yellow);
    }

    svg.circle circle {
      transition: fill 0.25s ease-in-out;
    }

    svg.circle:hover circle {
      fill: var(--transform-yellow);
    }
  }

  .grid-logo {

    path {
      transition: fill 0.25s ease-in-out;
    }

    &:hover {
      path {
        fill: var(--transform-yellow);
      }
    }
  }

  .user-settings {
    .user-settings-logo {
      cursor: pointer;
    }
  }
}