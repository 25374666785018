.reminders-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .chip-container {
        display: flex;
        margin-top: 10px;
        align-items: center;
        flex-direction: row;
        gap: 16px;
    }

    .form {
        width: 100%;
        display: flex;
        flex-direction: column;

        .send-to {
            margin: 16px 0 0 0;
        }
    }

    .edit-email {
        margin: 20px 0, 0;
    }
}