.survey-filter-button {
    background-color: white;
    padding: 4px 10px;
    max-height: 32px;
    box-shadow: none !important;
    background-color: transparent !important;
    color: var(--text-lightGrey) !important;
    border-radius: 2px !important;
    display: flex;
    gap: 6px;

    .survey-filter-button-text {
        font-size: 14px;
        font-weight: 400 !important;
        color: var(--text-lightGrey);
        letter-spacing: 0 !important;
        transition: color 0.3s ease-in-out;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
    }

    svg {
        width: 100%;
        height: 100%;

        path,
        rect {
            fill: var(--text-lightGrey);
            ;
            transition: fill 0.3s ease-in-out;
        }
    }

    &:hover {
        color: var(--transform-darkBlueTwo) !important;
        box-shadow: none !important;

        .survey-filter-button-text {
            color: var(--transform-darkBlueTwo);
        }

        svg {
            path {
                fill: var(--transform-darkBlueTwo);
            }
        }
    }

    &.selected {
        background-color: var(--app-lightestGrey) !important;
        color: var(--transform-darkBlueTwo) !important;
        box-shadow: none !important;
        z-index: 10;

        .survey-filter-button-text {
            font-weight: 500 !important;
        }

        .survey-filter-button-text {
            color: var(--transform-darkBlueTwo);
        }

        svg {
            path {
                fill: var(--transform-darkBlueTwo);
            }
        }
    }
}