@import '../../../../assets/variables';

.publish-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  // .title {
  //   color: var(--colors-blue);
  //   font-size: 18px;
  //   font-weight: bold;
  //   text-transform: uppercase;
  //   padding-bottom: 10px;
  // }

  // todo if only fab icon is in status container
  .MuiSvgIcon-root {
    margin-right: 5px;
  }

  .link {
    color: var(--colors-blue);
    border-bottom: 3px solid var(--colors-blue);
    padding: 0 5px;
    font-style: italic;
    font-weight: bold;
    cursor: pointer;
  }
}
