@import "../../../assets/variables.scss";

.recurring-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;

  .recurring-type {
    background-color: #f2f2f2;
    // border-top: 1px solid #3949b3;
    width: 550px;
  }

  .formgroup {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
  }

  .time-and-duration {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-left: 10px;
  }

  .effective-span {
    margin-left: 10px;
  }

  .effective-options {
    height: 35px;
    width: auto;
  }

  .ending-options {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 15px;
  }

  .time-picker {
    width: 120px;
    border: 1px solid #ecedfc;
    border-radius: 5px;
    padding: 0 10px;
    text-align: center;
    margin-top: 10px;
  }

  .date-picker {
    width: 150px;
    padding: 0 10px;
    text-align: center;
    margin-bottom: 20px;
  }

  .occurencies {
    width: 65px;
  }

  label {
    margin-left: 5px;
    color: #666;
  }

  .rate-select {
    display: inline-flex;
    gap: 10px;
  }

  .paper {
    display: flex;
    flex-direction: row;
    gap: 15%;
  }

  .start-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .start-checkbox {
    margin-left: -10px;
  }

  .select-label {
    margin-bottom: 15px;
  }

  .end-group {
    display: flex;
    margin-top: 7%;
    margin-right: 10px;

    .end-date {
      display: inline-flex;
      align-items: center;
      gap: 12px;
    }
  }

  .monthly-form-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .monthly-select {
    margin: 10px 0 0 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }

  .monthly-container {
    display: flex;
    flex-direction: column;
  }

  .form-control-text {
    font-size: 16px;
  }

  .select {
    height: 35px;
    width: 130px;
  }

  .select-options {
    width: 140px;
    height: 35px;
  }

  .custom-repeating-rate {
    margin: 10px 0 0 10px;
    display: inline-flex;
    gap: 15px;

  }

  .form-control-text-custom {
    margin-top: 10px
  }

  .custom-repeating-field {
    width: 45px;
    max-height: 20px;
  }

  .custom-select {
    width: 130px;
    height: 40px;
  }

  .repeating-rate {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 5px;
    margin-left: 5px;

    .repeating-field {
      width: 45px;
      text-align: right;
      margin-bottom: 10px;
    }
  }

  .day-selection {
    display: flex;
    flex-direction: column;
    margin-left: 13%;
    margin-top: -15px;

    .day-choose {
      .day-field {
        width: 45px;
      }
    }

    .order-choose {
      display: flex;

      .select-order {
        padding-right: 10px;
        width: 100px;
        height: 35px;
      }
    }
  }
}

input {
  color: var(--colors-blue);
  font-weight: 700;
}