.organization-container{
    width: 100%;
    background-color: white;
    border-radius: 5px;
    padding: 32px 40px 24px 40px;
    position: relative;
    min-height: 450px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .organization-info{
        margin-top: 40px;
        display: flex;
        gap: 110px;

        .company-logo{
            position: relative;
            display: flex;
            width: fit-content;
            height: fit-content;
        }
    }

    p.organization-complete-info{
        margin-top: auto;
        color: var(--transform-black);
    }
}