// breadcrumbs.scss
.linkStyle {
    text-decoration: none !important;
    display: flex !important;
    align-items: center;
  }
  
  .arrowIcon {
    margin: 0 13px;
  }
.breadcrumbsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.arrowIcon, .linkStyle {
  // Adjust sizes for small screens
  @media (max-width: 600px) {
    svg {
      width: 16px;
      height: 16px;
    }

    .linkStyle {
      font-size: 0.875rem; // Smaller text on mobile
    }
  }
}
