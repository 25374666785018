@import './variables';

.survey-status-label {
  border-radius: 2px;
  padding: 5px 10px;
  display: flex;
  width: fit-content;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: inset 0 0 0 0.3px white;

  &.draft.opacity {
    .icon {
      svg {
        path {
          fill: var(--survey-statusDraft);
        }
      }
    }
  }

  &.live.opacity {
    .icon {
      svg {
        path {
          fill: var(--survey-statusLive);
        }
      }
    }
  }

  &.completed.opacity {
    .icon {
      svg {
        path {
          fill: var(--survey-statusCompleted);
        }
      }
    }
  }

  &.scheduled.opacity {
    .icon {
      svg {
        path {
          fill: var(--survey-statusScheduled);
        }
      }
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: white;
      }
    }
  }
}