$color-pink: #E91E63;
$color-purple: #261440;
$color-dark-gray: #203455;
$color-light-gray: #EDEDED;
$color-shade-gray: #F2F2F2;
$color-medium-gray: #BDBDBD;
$color-gray: #666;
$color-light-pink: #e9adc1db;
$color-blue: #203455;
$color-light-blue: #BFC1E1;
$color-lighter-blue: #ECEDFC;
$color-green: #B1FFD2;
$color-red: #FFD0D0;
$color-survey-status-draft: #FF7D5F;
$button-hover-color: #1B497214;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// For iPhone 5/SE and smaller
$phone-xs:                   320px !default;
$phone-xs-min:               $phone-xs !default;;
// For iPhone 6/7/8/X
$phone-sm:                   375px !default;
$phone-sm-min:               $phone-sm !default;
// For iPhone 6/7/8 Plus
$phone-md:                   414px !default;
$phone-md-min:               $phone-md !default;
// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  600px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// Extra large screen
$screen-xl:                   1600px !default;
$screen-xl-min:               $screen-xl !default;

// So media queries don't overlap when required, provide a maximum
$phone-xs-max:               ($phone-sm-min - 1) !default;
$phone-sm-max:               ($phone-md-min - 1) !default;
$phone-md-max:               ($screen-xs-min - 1) !default;
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
