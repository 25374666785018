@import '../../../../assets/variables';

.choose-question-type-container {
  width: 745px;
  padding: 24px 0;
  display: flex;
  align-items: stretch; /* Ensure columns stretch to the same height */
  border: 1px solid transparent; /* Force container to stretch */

  .col {
    display: flex;
    flex-direction: column;
    width: 247px;

    .type {

      .type-text {
        font-size: 16px;
        line-height: 24px;
        color: #828282;
        padding-bottom: 12px;
        padding-left: 24px;
        padding-right: 24px;
        margin-top: 16px;
      }

      .type-text:first-child {
        margin-top: 0;
      }

      .question {
        cursor: pointer;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        height: 38px;
        margin-bottom: 8px;
        align-items: center; /* Center items vertically */
        justify-content: flex-start; /* Center items horizontally */
        
        .icon {
          margin-right: 6px;
        }

        .label {
          color: #282828;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .question:hover {
        background-color: #EEF0F4;
      }
    }
  }

  .col:not(:last-child) {
    border-right: 2px solid #D7D7D7
  }
}
