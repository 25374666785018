.register-page-content {
    padding: 40px 80px;
    box-sizing: border-box;
    width: 40%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 40px 20px;
        max-height: initial;
        height: 100%;
        overflow: initial;
    }

    .register-page-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        color: var(--transform-black);
    }

    .register-in-page-description {
        margin-top: 10px;
        color: var(--transform-black);
        margin-bottom: 40px;
        text-align: center;

        span {
            color: var(--transform-darkBlueTwo);
            font-weight: 500;
            cursor: pointer;
        }
    }

    .company-domain {
        position: relative;

        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: calc(-24px);

            @media screen and (max-width: 600px) {
                right: calc(12px);
            }
        }
    }


    .register-page-container {
        width: 100%;

        .form {
            margin: auto;
            height: auto;
            width: 100%;
            background-color: white;

            .transform-logo {
                width: 100%;
                padding: 25px 0;
                border-bottom: 4px solid var(--colors-blue);
                justify-content: center;
            }

            .title {
                color: var(--colors-darkGray);
                margin-bottom: -20px;
            }

            .subtitle {
                color: var(--colors-darkGray);
                margin-bottom: -5px;
            }

            .form-inner {
                width: 100%;

                .form-input {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;

                    label+.MuiInput-formControl {
                        margin-top: 0px;
                    }

                    .company {
                        width: 100%;
                    }

                    .email-error {
                        width: 100%;
                        box-sizing: border-box;
                    }

                    .input-company-domain {
                        margin-top: 15px;
                        width: 50%;
                    }

                    .email-phone-container {
                        display: flex;
                        flex-direction: row;
                        gap: 15%;
                        align-items: center;

                        .email {
                            width: 40%;
                        }

                        .phone-number {
                            margin-top: 18px;
                            width: 40%;
                        }
                    }

                    .first-last-name {
                        display: flex;
                        flex-direction: row;
                        gap: 15%;
                        align-items: center;

                        .first-name,
                        .last-name {
                            width: 40%;
                        }
                    }

                    .password-container {
                        position: relative;
                        width: 100%;

                        .password-error {
                            margin-bottom: 10px;
                        }
                    }

                    .confirm-password-container {
                        margin-top: 15px;
                        position: relative;
                        width: 100%;
                    }
                }
            }

            .submit-button {
                width: 100%;
                display: flex;
                margin-top: 32px;
                justify-content: right;
            }

            .tos-container {
                width: 80%;
                padding-top: 20px;

                .tos-text {
                    font-size: small;
                    font-family: "Arial", sans-serif;
                    color: #c0c0c0;
                    margin-left: 40px;
                    text-align: center;
                    justify-content: center;
                    width: 80%;
                }

                a {
                    text-decoration: none;
                }
            }

            .submit.disabled {
                opacity: 0.5;
            }

            .register-success-text {
                font-size: 20px;
                color: var(--colors-darkGray);
                margin: 30px 10px;

                h2 {
                    margin-bottom: -10px;
                    margin-top: -10px;
                }

                label {
                    text-align: center;
                    font-size: 16px;
                }

                .resend-button {
                    letter-spacing: 0;
                    line-height: 0;
                }
            }
        }
    }

    .bottom-button {
        display: flex;
        justify-content: right;
        margin: 0;
        margin-top: 40px;
        width: 100%;
    }

    .register-in-page-bottom {
        position: absolute;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: var(--text-lightGrey);
        bottom: 24px;
        left: 80%;
        transform: translateX(-50%);
        max-width: 280px;

        span {
            color: var(--transform-darkBlueTwo);
            cursor: pointer;
        }

        @media screen and (max-width: 600px) {
            position: initial;
            margin: 20px auto 0 auto;
            transform: none;
        }

        @media screen and (max-height: 900px) {
            position: initial;
            width: 100%;
            transform: initial;
            margin-top: 32px;
        }
    }
}