.view {
    display: flex;
    margin-left: 24px;
    gap: 4px;

    .view-button {
        padding: 6px;

        svg rect {
            transition: fill 0.3s ease-in-out;
        }

        &:hover {
            background-color: transparent;


            svg rect {
                fill: var(--transform-darkBlueTwo);
            }
        }

        &.active {
            background-color: var(--app-lightestGrey);


            svg rect {
                fill: var(--transform-darkBlueTwo);
            }
        }
    }
}