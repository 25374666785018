@import '../../../../assets/variables';

.settings-container {
  background-color: white;
  border-radius: 5px;
  padding: 32px 40px;

  .settings-section {
    background-color: white;
    border-radius: 5px;
    // padding: 20px;
    margin-bottom: 20px;
  }

  .settings {

  }

  .lists {

  }

  .respondents {

    .respondents-table {
      margin-top: 10px;
      max-height: 500px;
      overflow: auto;

      .header {
        background-color: var(--colors-mediumGray);
        border-bottom: 2px solid var(--colors-darkGray);
      }

      .row {
        display: flex;
        padding: 5px 0;
      }

      .data {
        width: 300px;
        overflow: auto;
        text-align: center;
      }

      .highlight {
        background-color: var(--colors-lightGray);
      }
    }
  }
  .file-actions {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;

    .save {
      .disabled {
        opacity: 0.4;
      }

      .error-count {
        margin-left: 10px;
        color: darkred;
      }
    }

    .save.icon {
      margin-right: 5px;
    }
  }

  .upload-button {
    background-color: var(--colors-blue);
    color: white;

  }


  .delete-button {
    margin-right: 10px;
  }

  .file-text {
    padding-left: 5px;
    color: var(--colors-darkGray);
  }

  .title {
    color: var(--colors-blue);
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 10px;
  }
}
