@import '../../../../assets/variables';

.advanced-settings {
  background-color: #F5F8FC;
  margin-left: -20px;
  margin-right: -20px;
  // margin-top: 15px;
  padding-bottom: 32px;

  .close-button{
    position: fixed;
    margin-top: 28px;
    right: 20px;
    min-width: 16px !important;
    height: 16px;
    padding: 10px 8px;
  }

  .close-button:hover {
    background-color: transparent;
  }

  .settings-button label {
    text-transform: none !important;
    font-size: 18px;
    color: #282828;
    font-weight: 500;
  }


  .content{
    align-items: center;
    display: grid;
    grid-column: 1/2;
    grid-row-gap: 15px;
    margin-left: 20px;
    margin-top: 15px;

    .short-name-section{
      align-items: center;
      display: grid;
      grid-column: 1/2;
      grid-row-gap: 6px;
      .text-field-short-ans{
        max-width: 25%;
        margin-left: 50px;
      }
    }
  }

  .form-inline {
    position: relative;
    .text-field-short-ans {
      max-width: 25%;
      margin-left: 50px;
    }
    .characters-left {
      position: absolute;
      bottom: 63%;
      display: inline-block;
      opacity: 0.3;
      background-color: #828282;
      border-radius: 30px;
      margin-left: 7px;
      padding: 2px 5px;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.40;
}

.advanced-settings .settings-button:hover {
  background-color: transparent !important;
  cursor: auto;
}