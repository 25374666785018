.survey-tile {
    width: 216px;
    box-shadow: 0px 4px 8px 0px #9B9B9B26, 0px 4px 4px 0px #9B9B9B40;
    border-radius: 4px;
    background-color: #FFFFFF;

    .survey-preview {
        position: relative;
        background: linear-gradient(110.41deg, var(--transform-darkBlueTwo) 4.1%, var(--transform-darkBlue) 97.4%);
        height: 125px;
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .status-icon {
            margin: 12px;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            max-width: 150px;
            max-height: 40px;
            transform: translate(-50%, -25%);
        }
    }

    .details {
        width: 100%;
        flex-grow: 1;
        position: relative;
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 90px;

        .title {
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.07px;
            color: var(--transform-black);
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .info {
            margin-top: auto;
            font-size: 12px;
            color: var(--text-lightGrey);
            width: calc(100% - 32px);
        }
    }

    .options-icon {
        outline: none;
        border: none;
        width: 28px;
        height: 28px;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: #F0F0F0;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        place-items: center;
        transition: background-color 0.3s ease-in-out;

        path{
          fill: var(--text-lightGrey);
          transition: fill 0.3s ease-in-out;
        }

        &:hover {
          background-color: var(--app-lightestGrey);

          path{
            fill: var(--transform-darkBlue);
          }
        }
      }
}