body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EDEDED;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html, body, #root, .app {
  height: 100%;
}

/*amplify login page styles*/

amplify-container {
  background: #3949B3 url('./assets/images/intro-image-people.png') no-repeat center;
  background-size: cover
}

.form-section-header {
  text-align: center;
}

.close-snackbar-icon {
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}