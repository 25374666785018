.component-container {
  .single-slider-question-container {
    display: flex;
    flex-direction: column;

    .slider-component {
      max-width: 65%;
      position: relative;
    }

    .slider-details {
      display: inline-flex;
      align-items: center;
      gap: 15%;
    }

    .slider-details-element {
      display: inline-flex;
      align-items: center;
      gap: 20px;
    }
  }

  .settings-button {
    text-transform: none;
    font-size: 18px;
    color: #282828;
  }
}

.input-field {
  width: 45px;
  height: 15px;
}

//Advanced settings
.single-slider-advanced-settings {
  display: grid;
  align-items: center;
  grid-column: 1/2;
  margin-left: 20px;
  margin-right: 20px;

  .default-slider-position {
    display: inline-flex;
    align-items: center;
    margin-top: 25px;
    padding-bottom: 15px;
    gap: 15px;

    .info-label {
      color: indianred;
      margin-top: 20px;
    }

    label {
      margin-top: 20px;
    }
  }

  .slider-start-end-container {
    display: inline-flex;
    gap: 20%;

    .slider-start-end {
      display: inline-flex;
      align-items: center;
      gap: 25px;

      .min-max-label {
        margin-top: 20px;
      }

      .slider-start-and-end-text {
        max-width: 250px;
        height: 20px;

      }
    }
  }
}

.single-slider-question-container {
  .MuiSlider-thumb {
    background-color: #3e517b; // Custom thumb color
  }

  .MuiSlider-track {
    background-color: #3e517b; // Custom track color
  }

  .MuiSlider-rail {
    background-color: #3e517b; // Custom rail color
  }

}

.MuiSlider-valueLabel {
  color: #3e517b !important;
}