.signin-page-content {
    box-sizing: border-box;
    width: 40%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; // Centriranje sadržaja vertikalno i horizontalno

    .signin-page-container {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        padding: 20px; // Dodavanje paddinga može pomoći da sadržaj ne "izlazi"
        box-sizing: border-box; // Uzima u obzir padding i border u širini i visini
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 40px 20px;
        max-height: initial;
        height: 100%;
        overflow: initial;
    }

    .signin-page-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        color: var(--transform-black);
    }

    .sign-in-page-description {
        width: 80%;
        margin: auto;
        margin-top: 10px;
        color: var(--transform-black);
        margin-bottom: 40px;
        line-height: 18px;
        text-align: center;

        span {
            color: var(--transform-darkBlueTwo);
            font-weight: 500;
            cursor: pointer;
        }
    }

    .form-container {
        width: 100%;
        max-width: 420px;
    }

    .form-inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        text-align: center;
    }

    .confirm-invitation-container {
        .password-error {
            width: 100%;
            text-align: left;
        }

        .confirm-password-error {
            width: 100%;
            text-align: left;
        }
    }

    .forgot-password-container,
    .post-confirmation-code-container {

        .email-forgot-input,
        .forgot-error,
        .confirmation-code-input,
        .new-pass-input,
        .success-message,
        .email-error-confirmation {
            width: 100%;
            box-sizing: border-box;
        }
    }

    .sign-in-container {
        .form-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;
        }
    }

    .bottom-buttons {
        display: flex;
        gap: 16px;
        justify-content: right;
        margin: 0;
        margin-top: 40px;
        width: 100%;
    }

    .text-button {
        position: absolute;
        bottom: 24px;
        left: 80%;
        transform: translateX(-50%);

        @media screen and (max-width: 600px) {
            position: initial;
            margin: 20px auto 0 auto;
            transform: none;
        }

        @media screen and (max-height: 500px) {
            position: initial;
            width: 100%;
            transform: initial;
            margin-top: 32px;
        }
    }
}