@media screen and (max-width: 1300px) {
    .team-table-container {
        // width: 90% !important;

    }

}

@media screen and (max-width: 799px) {
    .css-1adtkel-MuiTableContainer-root {
        width: 89%;
    }

    .team-table-paper {
        width: 99% !important;
        margin: 0 auto !important;
    }

    .search-add {
        display: block !important;
        justify-content: space-between;
    }

    .add-button-search {
        padding-top: 20px !important;
    }
}