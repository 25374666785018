.survey-container {
    width: 100%;
    display: flex;
    padding-left: 63px;
    margin: 0 auto;
    max-width: 1200px;

    &:has(.survey-action),
    &:has(.edit-tabs) {
        margin-top: 60px;
    }
}

.survey-container .teammates-container {
    width: 100%;
}

.survey-container .teammates-container .css-1hw29i9 {
    width: 100%;

}

@media screen and (max-width: 1500px) {

    .survey-container {
        width: 80%;
        display: flex;
    }

    .survey-container .team-table-container {
        width: 100% !important;
    }
}

@media screen and (max-width: 900px) {

    .survey-container:has(.survey-action),
    .survey-container:has(.edit-tabs) {
        margin-top: 120px;
    }
}