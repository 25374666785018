.my-teammates{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px #9B9B9B40, 0px 4px 8px 0px #9B9B9B26;
    border-radius: 4px;
    padding: 24px 24px 20px 24px;
    min-width: 420px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    position: relative;
    min-height: 224px;

    @media screen and (max-width: 600px){
        width: 100%;
    }

    .my-teammates-header{
        display: flex;
        justify-content: space-between;

        h3{
            font-size: 16px;
            margin: 0;
            line-height: 24px;
            font-weight: 500;
            text-transform: uppercase;
            color: #333333;
            letter-spacing: 0.07px;
        }
    
        h4{
            font-size: 20px;
            font-weight: 500;
            color: var(--transform-darkBlueTwo);
        }
    }

    ul{
        margin-top: 18px;
        padding: 0;

        li{
            padding: 9px 0px;
            border-bottom: 1px solid var(--app-lightGrey);
            gap: 12px;

            .MuiListItemAvatar-root{
                min-width: initial;
                min-height: initial;
                display: flex;

                svg{
                    width: 28px;
                    height: 28px;
                }
            }

            .MuiListItemText-root{
                padding: 0;
                margin: 0;
                max-width: 120px;

                span{
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    color: var(--transform-black);
                }
            }

            .apps{
                display: flex;
                gap: 5px;
                margin-left: auto;

                .app-name{
                    font-size: 14px;
                    padding: 3px 10px;
                    border-radius: 2px;
                    color: var(--transform-black);
    
                    &.survey{
                        background-color: #FFBD6F;
                    }
    
                    &.insights{
                        background-color: #FAE36E;
                    }
                }
            }
        }
    }

    .manage-button{
        outline: none;
        border: none;
        background-color: transparent;
        border-radius: 23px;
        width: fit-content;
        padding: 4px 14px;
        font-size: 15px;
        color: var(--transform-darkBlueTwo);
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        margin: auto auto 0 auto;

        &:hover{
            background-color: #0C255C12;
        }

        @media screen and (max-width: 600px){
            margin-top: 20px;
        }
    }

    .add-teammates-button{
        outline: none;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        margin-top: 12px;
        font-family: 'Roboto', sans-serif;

        .add-teammates-button-icon{
            display: flex;
            position: relative;

            svg rect{
                transition: fill 0.3s ease-in-out;
            }

            &::before{
                content: '';
                position: absolute;
                width: 30px;
                height: 30px;
                background-color: var(--transform-yellow);
                border-radius: 50%; 
                top: -5px;
                left: -5px;
                z-index: -1;
                transition: background-color 0.3s ease-in-out;
            }
        }

        p{
            margin: 0;
            font-size: 16px;
        }

        &:hover{
            .add-teammates-button-icon{

                svg rect{
                    fill: white;
                }

                 &::before{
                    background-color: var(--transform-darkBlueTwo);
                }
            }
        }
    }

    &:hover{
        box-shadow: 0px 4px 8px 0px #1B49726B, 0px 4px 4px 0px #1B49720A;
    }
}