.component-container {
  .settings-button {
    text-transform: none;
    font-size: 18px;
    color: #282828;
  }

  .multi-slider-question-container {
    display: flex;
    flex-direction: column;

    .MuiListItem-gutters {
      padding-left: 0 !important;
    }

    .MuiRadio-root {
      color: #3e517b !important;
    }

    .slider-list-item {
      display: inline-flex;
      flex-direction: row;
      gap: 40px;

      .slider-text-field {
        padding-bottom: 25px;

      }

      #outlined-helperText {
        width: 213px;
        height: 31px;
        border-radius: 4px;
      }

      .slider-component {
        padding-top: 25px;
        max-width: 65%;
        position: relative;
      }
    }

    .slider-details {
      display: inline-flex;
      align-items: center;
      gap: 15%;

      .slider-details-element {
        display: inline-flex;
        align-items: center;
        gap: 20px;
      }

      label {
        padding-top: 20px;
      }
    }

    .bottom-grid {
      display: inline-block;

      .all-required-switch {
        position: static;
        padding-left: 15px;
      }
    }

    .input-field {
      width: 60px;
      height: 15px;
    }
  }
}

//Advanced settings
.multi-slider-advanced-settings {

  .source-of-options {
    display: inline-flex;
    align-items: center;
    gap: 15px;
    margin-left: 70px;

    .simple-select {
      width: 172px;
      height: 40px;
    }
  }
}


.MuiSlider-thumb {
  background-color: #3e517b !important; // Custom thumb color
}

.MuiSlider-track {
  background-color: #3e517b !important; // Custom track color
}

.MuiSlider-rail {
  background-color: #3e517b !important; // Custom rail color
}


.MuiSlider-valueLabel {
  color: #3e517b !important;
}




.add-option-hover:hover {
  background-color: #DCE0E3 !important;
  // box-shadow: inset 0px 0px 0px 1.5px var(--transform-darkBlue) !important;
  color: var(--transform-darkBlue) !important;

  .add-colaborator-icon path {
    fill: var(--transform-darkBlue) !important;
  }
}

.add-option .upload-list-label {
  line-height: 0px !important;
}

.icon-button-end {
  cursor: pointer;
  padding: 6px !important;
  margin-left: 9px !important;
  margin-right: 0px !important;
}


.icon-button-end:hover svg circle {
  fill: #eef0f4;
}