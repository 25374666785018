.organization{
    display: flex;
    gap: 40px;
    margin-top: -10px;

    .organization-column{
        width: 240px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}