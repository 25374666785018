.text-button{
    outline: none;
    border: none;
    background-color: transparent;
    width: fit-content;
    font-weight: 400 !important;
    text-transform: none !important;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out !important;
    min-height: initial !important;
    height: initial !important;
    line-height: 17px !important;

    &.big{
        padding: 4px 14px !important;
    }

    &.small{
        padding: 4px 8px !important;
    }

    &:hover{
        background-color: #0C255C12 !important;
        box-shadow: none !important;
    }

    @media screen and (max-width: 600px){
        margin-top: 20px;
    }
}