.profile-container{
    max-width: 1200px;
    width: 100%;

    @media screen and (max-width: 1200px) {
        width: 90%; 
    }

    @media screen and (max-width: 600px) {
        width: 100%; 
        padding-inline: 32px;
    }
}