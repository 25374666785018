.preview {
  width: 360px;
  height: 584px;
  box-shadow: 0 5px 10px #B9B9B9;

  .page-container {
    height: calc(100% - 170px);
    text-align: center;
    justify-content: space-between;
    margin-top: 20px;

    .question-container-preview {
      height: 100%;
      width: 90%;
      justify-content: space-between;
      text-align: left;
      margin: 20px 0;

      .question-text {
        color: var(--colors-darkGray);
        font-weight: bold;
        font-size: 18px;
      }

      .question-explanation {
        color: var(--colors-darkGray);
      }

      .question {
        width: 100%;

        .slider-title {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: var(--colors-darkGray);
          margin-bottom: 15px;
        }
      }

      .question-actions {
        display: inline;

        .action-button {
          margin: 5px;
        }

        .button-next {
          color: white;
        }
      }
    }

    .progress-bar {
      display: inline-flex;
      height: 6px;

      width: 100%;
      margin-top: 0;

      .completed,
      .left {
        height: 100%;
        border-radius: 0px;
      }
    }

    .progress-bar-bottom {
      position: absolute;
      bottom: 0;
    }
  }


  .header-container {
    display: flex;
    border-radius: 0 0 16px 16px;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100px;
    padding: 0;
    box-shadow: 0 1px 14px rgba(0, 0, 0, 0.12), 0 5px 8px rgba(0, 0, 0, 0.14), 0 3px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;

    .placeholder-logo {
      max-height: 90%;
      max-width: 90%;
    }
  }

  .transform-logo {
    width: 80px;
  }

  .footer-container {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}