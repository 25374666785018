.style-container {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 32px 40px;
  border-radius: 5px;

  .options {

    .main-color,
    .navigation-color {
      margin-bottom: 15px;

      .color-full {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        margin-right: 12px;
      }

      .text {
        color: var(--colors-darkGray);
      }
    }

    .upload-logo {
      .file-text {
        margin-left: 5px;
      }
    }

    .theme-colors {
      margin-top: 60px;

      .title {
        margin-bottom: 20px;

        .text {
          color: var(--colors-blue);
        }
      }

      .choose-theme-color {
        .radio {
          margin-left: 6px;
          margin-right: 6px;

          .color-half {
            width: 36px;
            height: 36px;
            border-radius: 18px;
          }
        }

        .radio:first-child {
          margin-left: 0;
        }
      }
    }

    .text {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
    }
  }

  .color-input {
    padding-left: 5px;
    width: 120px;
  }

}