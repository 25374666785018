.branding-panel {
    width: 60%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    padding: 0px 80px;
    display: flex;
    align-items: center;
    gap: 35px;
    position: relative;
    box-sizing: border-box;

    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 40px 20px;
        min-height: initial;
        max-height: initial;
    }

    .branding-panel-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--transform-black);
        opacity: 0.9;
    }

    .branding-panel-content {
        display: flex;
        flex-direction: column;
        gap: 35px;
        z-index: 1000;

        svg {
            max-width: 100%;
        }

        p {
            color: var(--colors-white);
            font-weight: 300;
            font-size: 14px;
            line-height: 26px;
        }
    }
}