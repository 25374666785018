@import '../../../assets/variables.scss';

.edit-container,
.user-management-container {
    background-color: var(--colors-lightGray);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    flex-wrap: wrap;

    .edit-tabs {
        width: calc(100% - 63px);
        height: 60px;
        background-color: white;
        padding: 0 24px;
        box-sizing: border-box;
        gap: 12px;
        margin-left: 1px; // to cover the border of the sidebar
        flex-wrap: wrap;
        position: fixed;
        box-sizing: border-box;
        top: 56px;
        left: 63px;
        z-index: 999;

        @media screen and (max-width: 1100px) {
            min-height: auto;
            height: auto;
            padding-bottom: 20px;
        }

        .tabs {
            height: 100%;
            margin-right: auto;
            gap: 24px;
            position: relative;

            @media screen and (max-width: 1100px) {
                min-height: 60px;
                row-gap: 0;
                flex-wrap: wrap;
            }

            .tab {
                line-height: 24px;
                text-align: left;
                color: var(--transform-gray);
                text-transform: uppercase;
                cursor: pointer;
                transition: all 0.25s ease-out;
                gap: 5px;

                @media screen and (max-width: 800px) {
                    min-height: 60px;
                }

                &:hover {
                    color: var(--transform-black);
                }

                svg path {
                    fill: var(--transform-gray);
                    transition: fill 0.25s ease-in-out;
                }

                &:hover svg path {
                    fill: var(--transform-black);
                }

                & .tab-icon.stroke path{
                    transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
                }

                &:hover .tab-icon.stroke path{
                    stroke: var(--transform-black);
                    fill: var(--transform-black);
                }

                .icon {
                    display: flex;
                    margin-top: -1px;
                }
            }

            .tab.active {
                color: var(--transform-black);

                .tab-icon {
                    path {
                        fill: var(--transform-black);
                    }
                }

                .tab-icon.stroke path{
                    stroke: var(--transform-black);
                    fill: var(--transform-black);
                }
            }
        }

        .preview {
            background-color: var(--transform-darkBlueTwo);
            color: white;
            transition: background-color 0.25s ease-in-out;
            box-shadow: none;
            padding: 3px 10px;
            max-height: 30px;

            .preview-icon {
                margin-right: 5px;
            }

            &:hover {
                background-color: var(--transform-darkBlue);
            }
        }

        .add-colaborator {
            background-color: var(--colors-white);
            color: var(--transform-darkBlueTwo);
            box-shadow: inset 0px 0px 0px 1.5px var(--transform-darkBlueTwo);
            padding: 3px 10px;
            transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
            max-height: 30px;

            .add-colaborator-icon {
                margin-right: 5px;
            
                path{
                    transition: fill 0.3s ease-in-out;
                }
            }

            &:hover {
                background-color: #DCE0E3;
                box-shadow: inset 0px 0px 0px 1.5px var(--transform-darkBlue);
                color: var(--transform-darkBlue);

                .add-colaborator-icon path{
                    fill: var(--transform-darkBlue);
                }
            }
        }
    }

    .edit-content {
        width: 100%;
        margin: 0 auto;
        padding: 32px 0px 24px 0px;
    }

    .info-icon {
        color: var(--colors-blue);
        margin-left: 12px;
    }
}