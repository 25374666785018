.search-choose-question-container {

  .text-field {
    width: 60px;
    height: 15px;
  }
  .list-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;


    .select-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      .select {
        width: 172px;
        height: 30px;
      }
    }
    .column-preview {
      display: flex;
      margin-left: 20px;
      .select-columns {
        width: 172px;
        height: 30px;
      }
    }
    .autocomplete {
      padding-left: 15px;
    }
  }
  .allow_respondent_answers {
    padding-top: 30px;
    display: inline-flex;
    align-items: center;
  }
  .response-columns-checkboxes,
  .choose-item-name {
    padding-top: 15px;
    padding-left: 10px;
    display: inline-flex;
    align-items: center;
    .text {
      padding-right: 10px;
      color: #3949b3;
    }
  }

  .answers-per-question {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .maximum-number-of-responses {
      display: flex;
      margin-left: 20px;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      .text-field {
        margin-bottom: 20px;
      }
    }
  }
  .settings-button {
    text-transform: none;
    font-size: 18px;
    color: #282828;

  }
}

//Advanced settings
.search-choose-advanced-settings {
  display: grid;
  align-items: center;
  margin-left: 20px;

}

.Mui-selected:hover {
  background-color: transparent !important;
}

.MuiMenuItem-root:hover .MuiSvgIcon-root, .MuiMenuItem-root:hover .MuiListItemText-root {
  fill: #3e517b !important;
  color: #3e517b !important;
}
 .MuiButton-startIcon {
  margin-left: 0px !important;
}


