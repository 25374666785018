@import '../../../assets/variables.scss';

.start-checkbox {
  width: 100%;

  .MuiFormControlLabel-label {
    color: var(--colors-gray);
  }
}

.datetime-picker-container {
  color: var(--colors-gray);
  display: flex;
  flex-direction: column;
  gap: 20px;

  .starts,
  .ends {
    border: 1px solid var(--colors-lighterBlue);
    border-radius: 5px;
    padding: 0 10px;
    width: fit-content;
  }

  .date-picker {
    margin: 5px;
    width: 150px;
  }

  .time-picker {
    margin: 5px;
    width: 140px;
  }

  .MuiStack-root {
    padding: 0px;
  }

  // input {
  //   color: var(--colors-blue);
  //   font-weight: 700;
  // }

  // svg path {
  //   color: var(--colors-gray);
  // }
}

.MuiPickersPopper-root {

  .MuiMenuItem-root.Mui-selected,
  .MuiPickersDay-root.Mui-selected {
    background-color: var(--transform-darkBlueTwo);
  }

  .MuiDialogActions-root .MuiButton-root {
    color: var(--transform-darkBlueTwo);
  }
}