.input-search {
    padding: 0px 14px 0px 12px;

    fieldset {
        transition: 0.3s ease-in-out border;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: var(--transform-darkBlueTwo);
        border-width: 1px;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
        border-color: var(--text-mediumGrey);
    }

    input {
        padding: 8px 16px 8px 6px;
        font-size: 14px;
        line-height: 24px;
        height: 40px;
        font-family: 'Roboto', sans-serif;
        box-sizing: border-box;
    }

    .input-search-icon {
        margin-right: 0 !important;
    }

    .delete-icon {
        position: absolute;
        right: 12px;
        cursor: pointer;

        svg path {
            transition: 0.3s fill ease-in-out;
        }

        &:hover svg path {
            fill: var(--transform-darkBlueTwo);
        }
    }
}