.dialog-conent {
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
  height: 250px;
}
.send-invite-fab {
  margin: auto;
  padding: 20px;
  font-weight: 700;
}
.basic-field{
  width: 300px;
}
