.avatar.active {
    svg path {
        fill: var(--transform-yellow);
    }
}

.app-menu {
    .MuiPaper-root {
        left: 0 !important;
        top: 56px !important;
        border-radius: 0;
        box-shadow: none;
        background-color: var(--transform-black);

        .MuiList-padding {
            padding: 0px 0px 12px 0px;

            .MuiMenuItem-root {
                color: var(--colors-lightGray);
                font-size: 16px;
                display: flex;
                gap: 10px;
                padding: 5px 60px 5px 20px;
                transition: background-color 0.25s ease-in-out;
                font-weight: 700;
                font-family: 'Crimson Text', serif;
                width: 100%;

                .MuiListItemIcon-root {
                    min-width: initial;
                }

                &:hover {
                    background-color: #312D2E;
                }
            }
        }
    }
}