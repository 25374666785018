@import "../assets/variables";

.register-page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
  align-items: stretch;
  background-color: #ffffff;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}