.basic-info {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: -20px 0 10px 0;

    .full-name {
        padding-left: 15px;
    }

    .email {
        margin: -50px 0 0 16px;
        font-size: small;
        color: gray;
    }
}

.avatar {
    button {
        span {
            position: relative;

            &::before {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                margin-left: -3px;
                margin-top: -3px;
                width: calc(100% + 6px);
                height: calc(100% + 6px);
                border-radius: 50%;
                background-color: transparent;
                transition: background-color 0.25s ease-in-out;
                z-index: -1;
            }
        }
    }

    &:hover {
        button {
            span::before {
                background-color: var(--transform-yellow);
            }
        }
    }
}

.account-menu {
    .MuiPaper-root {
        right: 0;
        top: 56px !important;
        border-radius: 0;
        box-shadow: none;
        background-color: var(--transform-black);

        .MuiList-padding {
            padding: 15px 32px 15px 20px;
            width: max-content;

            .MuiMenuItem-root {
                color: var(--colors-lightGray);
                font-size: 14px;
                display: flex;
                gap: 10px;
                padding: 5px 0px;
                transition: color 0.25s ease-in-out;
                width: fit-content;

                .MuiListItemIcon-root {
                    min-width: initial;

                    svg path {
                        transition: fill 0.25s ease-in-out;
                    }
                }

                &:hover {
                    color: var(--transform-yellow);

                    .MuiListItemIcon-root svg path {
                        fill: var(--transform-yellow);
                    }
                }
            }
        }
    }
}