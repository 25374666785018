.status-container {
    justify-content: space-between;
    align-items: stretch;

    &>div {
        width: calc(31%);
        box-sizing: border-box;
        background-color: white;
        border-radius: 5px;
        justify-content: center;
        padding: 16px 18px;

        .manage:hover {
            color: var(--transform-darkBlue);
        }

        .error-button {
            background-color: white;
            color: var(--app-red);
            transition: background-color 0.25s ease-in-out, box-shadow 0.25 ease-in-out;
            box-shadow: none;
            padding: 3px 10px;
            max-height: 30px;
            width: fit-content;
            line-height: 1;


            .error-icon {
                margin-right: 5px;
            }

            &:hover {
                background-color: #E23D3212;
                box-shadow: 0px 0px 0px 1px inset var(--app-red);
            }
        }

        .error,
        .no-errors {
            margin: 10px 15px;
        }

        .error {
            background-color: var(--colors-red);
        }

        .no-errors {
            background-color: var(--colors-green);
        }

        .MuiSvgIcon-root {
            margin-right: 5px;
        }
    }
}