.survey-action {
  height: 60px;
  width: calc(100% - 63px);
  background-color: white;
  box-sizing: border-box;
  margin-left: 1px; // to cover the border of the sidebar
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  top: 56px;
  left: 63px;
  z-index: 999;

  .survey-action-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    max-width: 1200px;
  }

  @media screen and (max-width: 1350px) {
    height: auto;
    justify-content: center;
    padding: 10px 30px;
  }

  .search {
    .survey-search {
      padding: 0px 0px 0px 12px;

      input {
        padding: 8px 16px 8px 6px;
        font-size: 14px;
        line-height: 24px;
        height: 40px;
        font-family: 'Roboto', sans-serif;
        box-sizing: border-box;
      }

      .survey-search-icon {
        margin-right: 0 !important;
      }
    }
  }
}