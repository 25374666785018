@import '../../../assets/variables';

.surveys-container {
  justify-content: center;
  min-height: 250px;
  width: 100%;
  padding: 72px 0px;
  box-sizing: border-box;

  .tile-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 200px;
    gap: 30px;
    margin-bottom: 40px;
  }

  .surveys {
    width: 100%;
  }
}
.dialog-content{
  height: 100%;
  .buttons-flex {
    display: flex;
    flex-direction: row;
  }
  
  .assign-button{
    font-weight: 700;
    max-width: 300px;
  }

  .invite-user-cmp{
    margin-left: auto;
  }
}


.delete-button {
  background-color: #df1b1b;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px ;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
 }

.delete-button:hover {
  background-color: rgb(238, 87, 87);
}

.delete-header{  
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 20px;
}

.delete-text{  
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 20px;
}

.centered-button{  
  display: flex;
  justify-content: center;
}

.create-new-survey-tile{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 216px;
  gap: 25px;
  padding: 54px 0px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px 0px #9B9B9B26, 0px 4px 4px 0px #9B9B9B40;
  border-radius: 4px;
  transition: box-shadow 0.25s ease-in-out;
  cursor: pointer;
  
  .title{
    font-size: 14px;
    font-weight: 700;
  }

  svg ellipse{
    transition: fill 0.25s ease-in-out;
  }

  &:hover{
    box-shadow: 0px 4px 8px 0px #1B49726B, 0px 4px 4px 0px #1B49720A;
    
    svg ellipse{
      fill: var(--transform-darkBlue);
    }
  }
}
