@import '../../../../../../assets/variables';

.text-box-question-container {
  .use-max-characters {
    display: block;
    width: 70%;
  }

  .options {
    width: 100%;
    display: flex;
    margin-left: 30px;
    margin-top: 15px;

    .MuiFormHelperText-root.Mui-error {
      position: absolute;
      bottom: -18px;
    }
  }  


}

//Advanced settings styling
.advanced-settings-text-box {
  align-items: center;
  display: grid;
  grid-column: 1/2;
  grid-row-gap: 15px;
  margin-left: 20px;
}

.customize-response-section {
  align-items: center;
  display: grid;
  grid-column: 1/2;
  .response-field-text {
    max-width: 30%;
    margin-left: 50px;
  }
}
.info-label {
  padding-left: 5px;
  color: #828282;
  font: 12px Helvetica, sans-serif;
}

