.resources-card{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px #9B9B9B40, 0px 4px 8px 0px #9B9B9B26;
    border-radius: 4px;
    padding: 24px 95px 34px 24px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    justify-content: space-between;
    max-width: 374px;

    @media screen and (max-width: 600px){
        width: 100%;
        max-width: initial;
        padding: 24px 24px 29px 24px;
    }

    h3{
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        color: #333333;
        letter-spacing: 0.07px;
    }

    .resources-items{
        margin-top: 27px;
        display: flex;
        gap: 32px;
        flex-direction: column;
        height: 100%;

        .resource-item{
            display: flex;
            align-items: center;
            gap: 14px;
    
            .resource-icon{
                width: 40px;
                display: flex;
                justify-content: center;
            }
    
            .resource-description{
                h4{
                    font-size: 16px;
                    color: var(--transform-black);
                }

                a{
                    color: var(--transform-darkBlue);
                    font-size: 14px;
                    font-weight: 500;

                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}