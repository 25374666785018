@import "../../../assets/variables";

.published-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fab-button-blue {
  width: 120px;
  height: 40px;
  border-radius: 20px;
}

.used-invitation-contanier {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  label {
    font-size: 16px;
    font-weight: 700;
  }

  .used-invitation {
    width: 700px;
    font-size: 16px;
    font-family: "Arial", sans-serif;
  }
}

.action-buttons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .action {
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    padding: 4px 12px;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: none;
    background-color: var(--colors-orange);
    color: white;
    height: initial;

    span{
      line-height: 16px;
    }

    &:hover{
      background-color: #ff7700;
    
    }
  }
}

.email-container {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  min-height: 400px;

  .menu {
    width: auto;
    background-color: var(--transform-darkBlueTwo);
    border-radius: 5px 0 0 5px;
    padding-top: 16px;
    display: flex;
    gap: 12px;
    min-width: 200px;

    .menu-tab {
      height: 32px;
      width: calc(100% - 80px);
      padding-left: 20px;
      padding-right: 60px;
      color: white;
      cursor: pointer;
      display: flex;
      transition: all 0.3s ease-out;
      text-transform: uppercase;
      font-weight: 500;
    }

    .menu-tab.active {
      background-color: white;
      color: var(--transform-darkBlueTwo);
    }
  }

  .content {
    padding: 20px 24px;
    flex-grow: 1;

    .publish-type-tab.active {
      svg path {
        fill: var(--transform-black);
      }

      p {
        color: var(--transform-black);
      }
    }

    .tab-container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .link-container {
        display: flex;
        flex-direction: column;
        gap: 36px;
      }

      &.disabled-block{
        pointer-events: none;
        opacity: 0.45;
      }

      /* For DataPicker */
      &:not(.disabled-block) .flex-row-center.disabled-block {
        opacity: 0.45;
      }

      .switch-grid {
        margin-left: -10px;
        display: inline-flex;
        gap: 10px;
        align-items: center;
      }

      .anon-contanier,
      .embeded-contanier {
        display: flex;
        flex-direction: column;
        gap: 10px;

        button {
          background-color: var(--transform-darkBlueTwo);
          color: white;
          transition: background-color 0.25s ease-in-out;
          box-shadow: none;
          padding: 3px 10px;
          margin-top: 6px;
          max-height: 30px;
          width: fit-content;
          line-height: 1;


          .copy-icon {
            margin-right: 5px;
          }

          &:hover {
            background-color: var(--transform-darkBlue);
          }
        }
      }

      .anonymous-link {
        width: 85%;

        .MuiInputBase-input.Mui-disabled{
          color: var(--text-darkGrey);
        }

        .MuiInput-underline:before {
          opacity: 0.42;
          border-bottom: 1px solid var(--text-lightGrey);
        }
      }

      .embeded-link {
        width: 85%;
        text-align: center;
        
        .MuiOutlinedInput-root{
          border-radius: 0px;
        }

        .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
          border: 1px solid var(--text-lightGrey);
          opacity: 0.42;
        }
      }
    }

    // publishing tab
    .form {
      width: 100%;
      display: flex;
      flex-direction: column;

      .send-to {
        margin: 16px 0 0 0;
      }
    }

    .publish {
      .loader {
        margin-right: 20px;
      }

      .survey-published {
        padding: 5px 8px;
        margin-left: 10px;
        background-color: var(--colors-lighterBlue);
        border-radius: 10px;
        width: fit-content;
      }
    }

    .publish-tab {
      .text {
        margin: 10px;
        color: var(--colors-gray);
        text-transform: uppercase;
      }
    }

    .publish-tab.active {
      .survey-status,
      .respondent-status {
        display: flex;
        // justify-content: space-between;
        align-items: flex-start;
        width: 100%;
      }

      .survey-status {
        .left {
          display: flex;
          align-items: flex-start;

          .action:not(:last-child) {
            border-right: 2px solid var(--colors-blue);
          }
        }
      }

      .respondent-status {
        margin-top: 24px;
        align-items: stretch;
        display: flex;

        & > div {
          padding: 12px 0px;
        }

        .respondent-total {
          border-right: 1px solid #D7D7D7;
          padding-right: 56px;
        }

        .bounced {
          align-items: center;
          padding: 0px 56px;
          border-right: 1px solid #D7D7D7;

          .bounced-row {
            justify-content: center;
            gap: 16px;

            .resolve {
              border-radius: 20px;
              font-weight: 400;
              font-size: 14px;
              padding: 4px 12px;
              letter-spacing: 0.07px;
              text-transform: uppercase;
              cursor: pointer;
              box-shadow: none;
              background-color: white;
              color:  var(--transform-darkBlueTwo);
              box-shadow: 0px 0px 0px 1px inset  var(--transform-darkBlueTwo);;
              height: initial;
          
              span{
                line-height: 16px;
              }
          
              &:hover{
                background-color: #DCE0E3;
                box-shadow: inset 0px 0px 0px 1px var(--transform-darkBlue);
                color: var(--transform-darkBlue);
              }
            }
          }
        }

        .stats {
          padding-left: 56px;
          display: grid;
          grid-template-columns: repeat(3, max-content);
          grid-column-gap: 32px;
          grid-row-gap: 10px;
          color: var(--text-mediumGrey);
          font-size: 14px;
        }
      }

      // all direct children of respondent-status
      .respondent-status>* {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .number-big {
        display: flex;
        flex-direction: column;
        align-items: center;

        .number {
          font-size: 36px;
          color: var(--colors-darkGray);
        }
      }
    }

    .download-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .download-button {
        position: center;
      }
    }
  }
}